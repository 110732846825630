import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import PortableContent from "./PortableContent";
import ScrollProgress from "./ScrollProgress";
import TableContents from "./TableContents";
import AdBanner from "./adBanner";
import { PortableText } from "@portabletext/react";

let options = {
  root: null,
  rootMargin: "0% 0% -50% 0%",
  threshold: 0,
};

const ArticleC = ({ article, title }) => {
  let previous = "";

  useEffect(() => {
    const sections = Array.from(
      document.querySelectorAll("h1, h2, h3, h4, h5")
    );

    const scrollHandler = (entries) => {
      return entries.forEach((entry) => {
        const section = entry.target;
        const sectionId = section?.id;

        const sectionLink = document.querySelector(`a[href="#${sectionId}"]`);
        const previousLink = document.querySelector(`a[href="#${previous}"]`);

        if (entry.isIntersecting) {
          sectionLink?.classList.add("item-visible");

          if (previous !== sectionId) {
            previousLink?.classList.remove("item-visible");
          }
          previous = sectionId;
        } else {
          if (previous !== sectionId) {
            sectionLink?.classList.remove("item-visible");
          }
        }
      });
    };
    // Creates a new scroll observer
    const observer = new IntersectionObserver(scrollHandler, options);

    sections.forEach((section) => observer.observe(section));
  }, []);

  //   console.log("===article", article?.sidebarImages);

  return (
    <div className="flex max-w-[1300px] mx-auto">
      {article?.tableContent && <TableContents data={article?.tableContent} />}
      <ScrollProgress />
      <div className="container grid grid-cols-3 gap-6 px-3 mt-10 article">
        <div
          className={
            article?.sidebarImages?.length > 0
              ? "col-span-3 md:col-span-2"
              : "col-span-3"
          }
        >
          <div
            className={
              "bg-black/[.02] rounded-[33px] rounded-b-[33px] lg:rounded-b-none px-5 lg:px-10 pt-8 lg:pt-[59px] pb-[45px] lg:pb-[90px]"
            }
          >
            <div className="flex flex-wrap text-sm font-lora">
              <div>
                <Link
                  className="text-[#AF872D] hover:underline"
                  to={`/blog/${article.category[0]?.slug?.current}`}
                >
                  {article.category[0]?.title}
                </Link>
              </div>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <div>
                Reading Time: {article?.readTime ? article?.readTime : 5}{" "}
                minutes
              </div>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <div>Last Updated On: {article?._updatedAt}</div>
            </div>
            <div>
              <h1 className="text-4xl leading-[46px] mt-6 text-black">
                {title}
              </h1>
            </div>
            {article?.summary && (
              <div className="mt-4">
                <p className="text-sm font-lora opacity-70 article-summary">
                  {article?.summary}
                </p>
              </div>
            )}
            <div className="mt-9 article-body">
              <div className="text-base leading-5 prose max-w-none article-c">
                <PortableContent article={article} />
              </div>
            </div>
            {/* <div className="mt-20">
              <div id="disqus_thread"></div>
            </div> */}
          </div>
        </div>
        {article?.sidebarImages?.length > 0 && (
          <div className={"col-span-3 md:col-span-1"}>
            <div
              className={
                "bg-black/[.02] rounded-[33px] text-center p-8 mb-10 mt-10 relative h-[calc(100%-460px)] md:h-[calc(100%-320px)]"
              }
            >
              {article?.sidebarImages?.map((item, i) => {
                if (item?._type === "articleImage") {
                  return (
                    <a
                      key={i}
                      href={item?.anchorLink}
                      target={"_blank"}
                      className={`no-underline block mb-4 ${
                        article?.sidebarImages?.length === i + 1
                          ? "sticky top-10"
                          : ""
                      }`}
                      rel="noreferrer noopener"
                    >
                      <GatsbyImage
                        image={item?.asset?.gatsbyImageData}
                        alt={item?.alt}
                      />
                    </a>
                  );
                } else if (item?._type === "codeBlock") {
                  return (
                    <div
                      key={i}
                      className={
                        article?.sidebarImages?.length === i + 1
                          ? "sticky top-10"
                          : ""
                      }
                      dangerouslySetInnerHTML={{ __html: item?.code }}
                    />
                  );
                } else if (item?._type === "globalCodeBlock") {
                  return (
                    <div
                      key={i}
                      className={
                        article?.sidebarImages?.length === i + 1
                          ? "sticky top-10"
                          : ""
                      }
                      dangerouslySetInnerHTML={{ __html: item?.code }}
                    />
                  );
                } else if (item?._type === "globalEmbedCode") {
                  return (
                    <div
                      key={i}
                      className={
                        article?.sidebarImages?.length === i + 1
                          ? "sticky top-10"
                          : ""
                      }
                    >
                      <div className="pt-1">
                        <div
                          className="flex justify-center"
                          dangerouslySetInnerHTML={{ __html: item?.code }}
                        ></div>
                        {item?._rawAttribution &&
                          item?._rawAttribution.length > 0 && (
                            <div className="my-4 text-sm leading-6 text-[#676a6b] text-center">
                              <PortableText value={item._rawAttribution} />
                            </div>
                          )}
                      </div>
                    </div>
                  );
                } else if (item?._type === "adBlock") {
                  return (
                    <div
                      className={
                        article?.sidebarImages?.length === i + 1
                          ? "sticky top-10"
                          : ""
                      }
                    >
                      <AdBanner slot={item?.slot} key={i} />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`text-left pb-8 ${
                        article?.sidebarImages?.length === i + 1
                          ? "sticky top-10"
                          : ""
                      }`}
                      key={i}
                    >
                      <h4 className="mb-2 text-xl font-medium">
                        Related Links
                      </h4>
                      {item?.links?.map((link) => {
                        if (link?.type === "internal") {
                          return (
                            <Link
                              to={link?.path}
                              className="block underline font-ssp"
                            >
                              {link?.label}
                            </Link>
                          );
                        } else {
                          return (
                            <a
                              href={link?.url}
                              target={"_blank"}
                              className={"underline block mt-2 font-ssp"}
                              rel="noreferrer noopener"
                            >
                              {link?.label}
                            </a>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleC;
