import { PortableText } from "@portabletext/react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { Fragment } from "react";
import ArticleC from "../components/ArticleC";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
// import LocalScript from "../components/LocalScript";
import LocalScript2 from "../components/LocalScript2";
import { SEO } from "../components/SEO";

const Article = ({ data: { sanityArticle, sanitySite }, location }) => {
  return (
    <Fragment>
      <LocalScript2
      // isHeadScript
      // html={`!function(e,t){(e=t.createElement("script")).src="https://cdn.convertbox.com/convertbox/js/embed.js",e.id="app-convertbox-script",e.async=true,e.dataset.uuid="a1ce5f9e-d630-4668-be5b-3554f61e94a7",document.getElementsByTagName("head")[0].appendChild(e)}(window,document);`}
      // firstRemoveScript="https://cdn.convertbox.com/convertbox/js/embed.js"
      />
      {/* <LocalScript
        html={`
            var disqus_config = function () {
              this.page.url = 'https://nickwolny.com/${location?.pathname}';
              this.page.identifier = '${sanityArticle?.id}'; 
            };
            (function() {
            var d = document, s = d.createElement('script');
            s.src = 'https://nick-wolny.disqus.com/embed.js';
            s.setAttribute('data-timestamp', +new Date());
            (d.head || d.body).appendChild(s);
            })();
          `}
      /> */}
      <Layout headerData={sanitySite?.header}>
        <div>
          {sanityArticle?.featuredImage?.asset?.extension === "image" ? (
            <GatsbyImage
              className="w-full"
              image={sanityArticle?.featuredImage?.asset?.gatsbyImageData}
              imgClassName="w-full"
              alt={sanityArticle?.heading}
              style={{
                borderBottomLeftRadius: 14,
                borderBottomRightRadius: 14,
              }}
            />
          ) : (
            <div className="w-full">
              <img
                className="w-full"
                src={sanityArticle?.featuredImage?.asset?.url}
              />
            </div>
          )}
          {sanityArticle?._rawFeaturedImageattribution && (
            <div className="mb-0 prose max-w-none">
              <div className="mt-2 text-sm leading-6 text-[#676a6b] text-center attribution">
                <PortableText
                  value={sanityArticle?._rawFeaturedImageattribution}
                />
              </div>
            </div>
          )}
        </div>

        <ArticleC article={sanityArticle} title={sanityArticle?.heading} />
      </Layout>
      <Footer data={sanitySite?.footer} />
    </Fragment>
  );
};

export default Article;
export const Head = ({ data: { sanityArticle } }) => {
  return <SEO metadata={sanityArticle?.pageMetadata || {}} />;
};
export const query = graphql`
  query ($categoryId: String!, $articleId: String!) {
    sanityArticle(
      category: { elemMatch: { id: { eq: $categoryId } } }
      id: { eq: $articleId }
    ) {
      featuredImage {
        asset {
          extension
          url
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
      _rawFeaturedImageattribution
      templateType
      slug {
        current
      }
      pageMetadata {
        schema
        description
        keywords
        canonical
        title
        image {
          asset {
            url
          }
        }
      }
      heading
      id
      category {
        slug {
          current
        }
        title
        id
        description
      }
      author {
        name
        id
        email
        bio
      }
      _rawContent
      tableContent {
        title
        items
      }
      _createdAt(formatString: "MMMM DD YYYY")
      _updatedAt(formatString: "MMMM DD YYYY")
      readTime
      summary
      sidebarImages {
        ... on SanityArticleImage {
          _type
          alt
          anchorLink
          title
          asset {
            gatsbyImageData
          }
        }
        ... on SanityRelatedLinks {
          links {
            type
            label
            path
            url
          }
          _type
        }
        ... on SanityCodeBlock {
          _type
          code
        }
        ... on SanityAdBlock {
          _type
          slot
        }
        ... on SanityGlobalEmbedCode {
          _type
          title
          code
          _rawAttribution
        }
        ... on SanityGlobalCodeBlock {
          _type
          title
          code
        }
      }
    }
    sanitySite {
      header {
        links {
          type
          label
          path
          url
          childMenu {
            type
            label
            path
            url
          }
        }
      }
      footer {
        copyright
        topLinks {
          label
          path
        }
        bottomLinks {
          label
          path
        }
        facebook
        linkedIn
        instagram
        medium
        substack
        youtube
        tiktok
      }
    }
  }
`;
